export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDmfx8eGFNmVEhLGQ-KC74Bg56N-wZWvUA',
    authDomain: 'odss-mobile.firebaseapp.com',
    databaseURL: 'https://odss-mobile.firebaseio.com',
    projectId: 'odss-mobile',
    storageBucket: 'odss-mobile.appspot.com',
    messagingSenderId: '666985738510',
    appId: '1:666985738510:web:5d39ca3ecb5be83744275f',
    measurementId: 'G-MSQSNBVPBK',
  },
  api: (): string => {
    if (!localStorage.hasOwnProperty('apiKey') || localStorage.getItem('apiKey') === 'odss') {
      return 'https://6w1jcv46t6.execute-api.ap-northeast-1.amazonaws.com/odss/';
    }

    if (localStorage.getItem('apiKey') === 'futaba') {
      return 'https://7d5usv9j67.execute-api.ap-northeast-1.amazonaws.com/futaba/';
    }

    throw new Error('データベースが適切に選択されていません');
  },
};
