export const SlipType = {
  1: '売上',
  2: '仕入',
  3: '移動',
  // 4: '移動(to)',
  5: '棚卸',
  6: '見積',
  7: '返品',
  8: '仕入返品',
  // 9: '仕入発注',
  12: '多目的',
};

export const SlipTypeAll = {
  1: '売上',
  2: '仕入',
  3: '移動',
  4: '移動(to)',
  5: '棚卸',
  6: '見積',
  7: '返品',
  8: '仕入返品',
  9: '仕入発注',
  12: '多目的',
};

export const SearchSlipType = {
  '1,7': '売上・返品',
  '2,8': '仕入・仕入返品',
  3: '移動',
  4: '移動(to)',
  5: '棚卸',
  6: '見積',
  7: '返品',
  1: '売上',
  2: '仕入',
  8: '仕入返品',
  9: '仕入発注',
  12: '多目的',
};

export const SearchSlipDefault = {
  slipNo: null,
  startDate: null,
  endDate: null,
  slipType: '1,7',
  taskType: null,
  information: null,
  companyId: null,
  subjectId: null,
  reqId: null,
  onlyNoTransaction: false,
  createdTaskUser: null,
};

export const companySettings = {
  close: {
    5: '5日',
    10: '10日',
    15: '15日',
    20: '20日',
    25: '25日',
    30: '末日',
    99: '都度',
  },
  site: {
    1: '当月',
    2: '翌月',
    3: '翌々月',
    99: '都度',
  },
  paymentDate: {
    5: '5日',
    10: '10日',
    15: '15日',
    20: '20日',
    25: '25日',
    30: '末日',
    99: '都度',
  },
  calc: {
    1: '四捨五入',
    2: '切捨',
    3: '切上',
  },
  type: {
    1: '得意先',
    2: '仕入先',
  },
  rule: {
    1: '振込',
    2: '集金',
    3: '持参',
    4: '現金',
    5: 'クレジットカード',
  },
};

export const subjectType = {
  1: '居宅',
  2: '箱物',
  3: '修理',
  4: 'その他',
};

export type IDatabase = 'odss' | 'futaba';

export const defaultAppLaunchConstant = {
  task: [
    {
      id: 301,
      label: '配達待ち',
    },
    {
      id: 302,
      label: '配達してます',
    },
    {
      id: 303,
      label: '配達完了',
    },
    {
      id: 304,
      label: '店頭引取待ち',
    },
    {
      id: 305,
      label: '店頭渡完了',
    },
    {
      id: 306,
      label: '前出し完了',
    },
    {
      id: 307,
      label: '商品揃い待ち',
    },
    {
      id: 308,
      label: '客指示待ち',
    },
    {
      id: 309,
      label: '集金に行って',
    },
    {
      id: 310,
      label: '引き取りに行って',
    },
    {
      id: 300,
      label: '非表示',
    },
  ],
};

export const defaultAddition = () => ({
  companyName: '',
  employeeName: '',
  repName: '',
  subjectName: '',
});

export const defaultLogin = () => ({
  email: '',
  password: '',
  database: 'odss' as IDatabase,
});
